@import 'src/styles/theme.scss';
.ac-modal {
	position: relative;
	border-radius: 20px;
	.ac-modal-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		position: relative;

		.ac-modal-title {
			text-align: left;
			font-size: 18px;
			font-family: $QuicksandBold;
			color: #272e4d;
			line-height: 25px;
			width: 100%;
			padding: 18px 32px 24px;
		}
		.ac-modal-footer{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 164px;
			text-align: center;
			padding: 24px 20px;
			background-color: #fff;
			border-radius: 0 0 20px 20px;
		}
		.agreement-box {
			display: flex;
			line-height: 18px;
			p {
				font-size: 12px;
				color: #60636F;
				margin-left: 8px;
				text-align: left;
				span {
					font-weight: 500;
				}
				a{
					color: #60636F;
					text-decoration: underline;
				}
			}
			.check-box {
				&[type='checkbox'] {
					display: none;
				}
				&:checked + label {
					background-color: $colorPrimary;
				}
				&:checked + label::before {
					opacity: 1;
				}
			}
			label {
				flex:none;
				display: inline-block;
				width: 16px;
				height: 16px;
				border-radius: 4px;
				border: 1px solid rgba(#c7c9d3, 0.4);
				position: relative;
				cursor: pointer;
				&::before {
					display: inline-block;
					transition: all 0.5s;
					content: ' ';
					width: 8px;
					border: 2px solid #fff;
					height: 4px;
					border-top: none;
					border-right: none;
					transform: rotate(-45deg);
					top: 3px;
					left: 2px;
					position: absolute;
					opacity: 0;
				}
			}
		}
		.agreement-email {
			margin-top: 12px;
		}
		.agreement-content {
			margin-top: 8px;
		}
		.ac-modal-form{
			// flex:1;
			width: 100%;
			height: calc(446px - 164px);
			padding: 0px 32px 20px;
			overflow-y: auto;
		  padding-bottom: 164px;
			border-radius: 0 0 20px 20px;
			&::-webkit-scrollbar {
				border-radius: 20px;
			}
		}
	}
	.item_box{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.btn{
		display: block;
		margin: 0 auto;
	}
	@media screen and (max-width: ($screenPad - 1px)) {
		width: 320px !important;
		height: 524px;
		.ac-modal-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			.ac-modal-head {
				width: 100%;
				height: 100px;
				position: relative;
				border-radius: 12px;
				z-index: 1002;

				.img {
					width: 100.5%;
					height: 132px;
					position: absolute;
					top: -32px;
					left: -1px;
				}
			}

			.ac-modal-form {
				padding: 20px 20px 184px;
				height: calc(524px - 184px);
				.agreement-content {
					margin-top: 16px;
				}
				.form-item {
					margin-bottom: 24px;
					width: 100%;
					& > div {
						width: 100%;
						height: 44px;
					}

					label {
						display: block;
						font-size: 14px;
						font-family: $OpenSans;
						font-weight: 500;
						color: $colorTxtTitle;
						line-height: 18px;
						margin-bottom: 16px;
						display: block;
					}
					.phoneNumber > div > div:nth-of-type(2) {
						margin-left: 0 !important;
					}
					.grade-select,
					.subject-select {
						& > div {
							padding: 0;
							border: none;
							height: 44px;
							border-radius: 27px;
							input {
								font-weight: 500;
								color: #272e4d;
								width: 265px;
								border-radius: 27px;
								border: 1px solid rgba(199, 201, 211, 0.4);
								padding: 8px 27px;
								font-size: 16px;
							}
						}
					}
					.phone-select {
						display: flex;
						width: 100%;
						border: 1px solid rgba(199, 201, 211, 0.4);
						border-radius: 27px;
						padding: 0 24px;
						& > div {
							width: 134px;
							border: none;
							& > div {
								border: none;
							}
						}
						input {
							border: none;
							padding: 0;
							width: 128px;
						}
						span {
							width: 32px;
							span {
								width: 0 !important;
							}
						}
						.phone-number {
							width: 150px;
							padding: 0;
						}
					}

					input {
						height: 44px;
						background: #ffffff;
						&::placeholder {
							font-size: 16px;
							font-family: $OpenSans;
							font-weight: 500;
							color: $colorPlaceholder;
						}
					}
				}
			}
			.ac-modal-footer {
				margin-top: 13px;
				height: 184px;
				.btn {
					width: 240px;
					height: 44px;
					background: linear-gradient(316deg, #ff6536 0%, #f32735 100%);
					border-radius: 22px;
					font-size: 16px;
					font-family: $OpenSansMedium;
					font-weight: 500;
					color: #fffffe;
					line-height: 44px;
					text-align: center;
					padding: 0;
				}
			}
		}
		.item_box{
			display: block;
		}
		.ac-modal-content{
			height: calc(524px - 100px);
		}
	}
	@media screen and (min-width: $screenPad) and (max-width: ( $screenPc - 1px)) {
		width: $screenPad !important;
		height: 644px;
		.ac-modal-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			min-width: 740px;
			.ac-modal-head {
				width: 100%;
				height: 159px;
				position: relative;

				.img {
					width: 100%;
					height: 240px;
					position: absolute;
					top: -87px;
				}
			}
			.ac-modal-form {
				.agreement-content {
					margin-top: -15px;
				}
				.form-item {
					display: inline-block;
					width: 316px;
					margin-top: 24px;
					& > div {
						width: 100%;
						height: 44px;
					}
					label {
						display: block;
						font-size: 14px;
						font-family: $OpenSansMedium;
						font-weight: 500;
						color: $colorTxtTitle;
						line-height: 18px;
						margin-bottom: 8px;
					}
					.grade-select,
					.subject-select {
						& > div {
							padding: 0;
							border: none;
							height: 44px;
							border-radius: 27px;
							input {
								font-weight: 500;
								color: #272e4d;
								width: 316px;
								border-radius: 27px;
								border: 1px solid rgba(199, 201, 211, 0.4);
								padding: 8px 27px;
								font-size: 16px;
							}
						}
					}

					input {
						height: 44px;
						background: #ffffff;
						&::placeholder {
							font-size: 16px;
							font-family: $OpenSans;
							font-weight: 500;
							color: $colorPlaceholder;
						}
					}
				}
			}
			.ac-modal-footer {
				height: 144px;
				.btn {
					width: 240px;
					height: 44px;
					background: linear-gradient(316deg, #ff6536 0%, #f32735 100%);
					border-radius: 22px;
					font-size: 16px;
					font-family: $OpenSansMedium;
					font-weight: 500;
					color: #fffffe;
					line-height: 44px;
					text-align: center;
					padding: 0;
				}
			}
		}
	}
	@media screen and (min-width: $screenPc) {
		width: $screenPad !important;
		height: 644px;
		.ac-modal-wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
			border-radius: 20px;
			.ac-modal-head {
				width: 100%;
				height: 179px;
				position: relative;
				border-radius: 20px;
				.img {
					width: 100%;
					height: 240px;
					position: absolute;
					top: -81px;
				}
			}
			.ac-modal-title {
				text-align: left;
				font-size: 18px;
				font-family: $QuicksandBold;
				color: #272e4d;
				line-height: 25px;
				width: 100%;
				padding: 18px 32px 24px;
			}
			.ac-modal-form {

				.form-item {
					display: inline-block;
					width: 316px;
					margin-top: 24px;
					& > div {
						width: 100%;
						height: 44px;
					}
					label {
						display: block;
						font-size: 16px;
						font-family: $OpenSans;
						font-weight: 400;
						color: $colorTxtTitle;
						line-height: 22px;
						margin-bottom: 16px;
					}

					.grade-select,
					.subject-select {
						& > div {
							padding: 0;
							border: none;
							height: 44px;
							border-radius: 27px;
							input {
								font-weight: 500;
								color: #272e4d;
								width: 316px;
								border-radius: 27px;
								border: 1px solid rgba(199, 201, 211, 0.4);
								padding: 8px 27px;
								font-size: 16px;
							}
						}
					}
					input {
						font-size: 16px;
						height: 44px;
						background: #ffffff;
						&::placeholder {
							font-size: 16px;
							font-family: $OpenSans;
							font-weight: 500;
							color: $colorPlaceholder;
						}
					}
				}
			}
			.ac-modal-footer {
				.btn {
					width: 240px;
					height: 44px;
					background: linear-gradient(316deg, #ff6536 0%, #f32735 100%);
					border-radius: 22px;
					font-size: 16px;
					font-family: $OpenSansMedium;
					font-weight: 500;
					color: #fffffe;
					line-height: 44px;
					text-align: center;
					padding: 0;
				}
			}
		}
	}
}

// TODO:临时处理组件样式，组件需要优化,暂时先这样
:global {
	div[class*='ac-modal-free-form'] {
		input:placeholder {
			color: $colorPlaceholder !important;
		}
		.close {
			z-index: 1010;
		}
		div[class='*phoneNumber'] {
			div[class='content-input'] {
				margin-left: 0 !important;
			}
		}

		span[class*='suffixer'] {
			cursor: pointer;
			span[class*='input-arrow-icon'] {
				font-family: 'iconfont' !important;
				transition: all 0.3s linear;
				&[class*='droped'] {
					transform: rotate(180deg);
				}
				&::before {
					content: '\e637';
					border-top-color: #73747d;
					font-size: 16px;
					border: none;
					position: static;
				}
			}
		}

		div[class*='phoneNumber'] {
			span[class*='arrow'] {
				i {
					color: #73747d;
				}
			}
		}
		div[class*='select-menu'] {
			width: 316px;
			height: 200px;
			padding: 8px 0;
			animation: Stretch 0.3s linear;
			div[class*='select-option'] {
				display: flex;
				align-items: center;
				color: $colorTxt;
				line-height: 18px;
				padding: 9px 24px;
				min-height: 44px;
				&:hover {
					background-color: $colorBgHover;
					font-weight: bold;
					color: $colorTxtTitle;
				}
				&[class*='selected'] {
					background-color: $colorTxt;
					font-weight: bold;
					color: #fff;
					span {
						display: none;
					}
				}
			}
		}
		.lanaguage{
			div[class*='select-menu']{
				height: 110px;
			}
		}

		span[class='placeholder'] {
			font-size: 16px !important;
			font-family: $OpenSans !important;
			font-weight: 500;
			color: $colorPlaceholder !important;
		}
		.close-icon {
			color: #fff;
		}
	}

	@media screen and (min-width: $screenPad) {
		div[class*='rc-trigger-popup'] {
			height: 388px;
			menu {
				animation: Stretch 0.3s linear;
			}
		}
		#app > div > div[class*='select-menu'] {
			animation: PhoneStretch 0.3s linear;
		}
	}
	//移动端动画处理
	@media screen and (max-width: ($screenPad - 1px)) {
		div[class*='rc-trigger-popup'] {
			height: 388px;
			menu {
				animation: SelectStretch 0.3s linear;
			}
		}
		#app > div > div[class*='select-menu'] {
			animation: PhoneStretch 0.3s linear;
		}
	}
}
.releative{
	position: relative;
}
.agreement_box{
	display: inline-block;
	margin: 0 auto;
}
