@charset "utf-8";
* {
	box-sizing: border-box;
}
body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input {
	margin: 0;
	padding: 0;
}

body {
	font-size: 16px;
}

img {
	border: none;
}

ul,
ol {
	list-style: none;
}

input,
select,
textarea {
	outline: none;
	border: none;
	background: #fff;
}

textarea {
	resize: none;
}

a {
	text-decoration: none;
}

button {
	border: none;
	cursor: pointer;
}
