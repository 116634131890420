@import 'reset';
@import './variable.scss';
@import '../components/dialogs/popup/styles.scss';
@import '../components/dialogs/modal/styles.scss';
@import '../components/dialogs/toast/styles.scss';
@import './fonts.scss';

:root {
	--prefix: 'ac';
}
//$global-font: San Francisco, PingFang SC, Montserrat, sans-serif;
$global-font: 'Helvetica Neue', 'Source Sans Pro', Arial, Tahoma, Verdana;

html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: $global-font;
	//user-select: none;
}

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

*:focus {
	outline: none;
}

a {
	text-decoration: none;
}

:global {
	.w1080 {
		max-width: 1080px;
	}
	#loading {
		display: none;
		background: transparent;
		&.active {
			display: flex;
		}
	}

	#loading-text {
		display: none;
	}

	input {
		font-family: $global-font;
	}

	.zmsp-no-scrollbar {
		::-webkit-scrollbar {
			display: none;
		}
	}
}

:global {
	.common-btn {
		&:hover {
			background: linear-gradient(316deg, #cf451c 0%, #c7121f 100%) !important;
			box-shadow: 0px 6px 11px 0px rgba(243, 39, 53, 0.2) !important;
		}
	}
}

:global {
	.scroll-bar {
		&::-webkit-scrollbar {
			width: 6px;
			height: 80px;
		}
		&::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.3);
			border-radius: 3px;
		}
	}
}
