.input {
	&.small {
		height: 34px;
		padding: 6px 8px;
		font-size: 14px;
		&.has-suffix,
		&.allow-clear {
			padding-right: 32px;
			.clear,
			.suffixer {
				flex: none;
				width: 32px;
			}
		}
		&.has-prefix {
			padding-left: 32px;
			.prefixer {
				flex: none;
				width: 32px;
			}
		}
	}
	&.middle {
		height: 44px;
		padding: 11px 24px;
		font-size: 14px;
		&.has-suffix,
		&.allow-clear {
			padding-right: 32px;
			.clear,
			.suffixer {
				flex: none;
				width: 32px;
			}
		}
		&.has-prefix {
			padding-left: 32px;
			.prefixer {
				flex: none;
				width: 32px;
			}
		}
	}
	&.large {
		height: 55px;
		padding: 16px 27px;
		font-size: 16px;
		&.has-suffix,
		&.allow-clear {
			padding-right: 45px;
			.clear,
			.suffixer {
				flex: none;
				width: 45px;
			}
		}
		&.has-prefix {
			padding-left: 45px;
			.prefixer {
				flex: none;
				width: 45px;
			}
		}
	}
	.raw-input {
		flex: auto;
		width: 100%;
		font-size: inherit;
	}
	.placeholder {
		font-size: inherit;
	}
}
.raw-input {
	box-sizing: border-box;
	height: 20px;
	border-radius: 6px;
	border: 1px solid rgba(199, 201, 211, 0.4);
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
	outline-style: none;
}
.compose-input {
	flex: auto;
	display: flex;
	background-color: #fff;
	border-radius: 6px;
	border: 1px solid rgba(199, 201, 211, 0.4);
	.raw-input {
		flex: auto;
		height: unset;
		padding: unset;
		border-radius: 0;
		border: none;
	}
	.placeholder {
		position: absolute;
		pointer-events: none;
		color: #9a9da9;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&.has-prefix {
		position: relative;
		.prefixer {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		}
	}
	&.has-suffix,
	&.allow-clear {
		position: relative;
		.clear,
		.suffixer {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		}
	}
	&.allow-clear.has-suffix {
		.clear {
			display: none;
		}
		&:hover {
			.clear {
				display: block;
			}
			.suffixer {
				display: none;
			}
		}
	}
}
.input-search-icon {
	width: 16px;
	height: 16px;
	img {
		width: 100%;
	}
}
.input-clear-icon {
	width: 12px;
	height: 12px;
	img {
		width: 100%;
	}
}
.input-arrow-icon {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		left: -6px;
		top: -2px;
		width: 0;
		height: 0;
		border-top: solid 8px #c7c9d3;
		border-left: solid 6px transparent;
		border-right: solid 6px transparent;
	}
	&.droped::before {
		transform: rotate(180deg);
	}
}
