@import 'src/styles/theme.scss';
.ac_en_test_modal {
	width: 720px !important;
	height: 624px;
	padding: 24px 32px 32px;
	.form_wrap {
		height: calc(100% - 68px);
		min-width: 656px;
	}
	.option_warp {
		height: 372px;
		overflow: hidden;
	}
	.option_content {
		height: inherit;
		overflow: auto;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		&::-webkit-scrollbar {
			width: 0 !important;
		}
	}
	.modal_title {
		font-size: 18px;
		font-weight: 600;
		color: #272e4d;
		line-height: 24px;
	}
	.question_title {
		font-size: 24px;
		font-weight: bold;
		color: #272e4d;
		line-height: 33px;
		padding: 40px 0 24px;
	}
	.item_com {
		cursor: pointer;
		display: inline-block;
		border-radius: 8px;
		border: 1px solid #cecfd5;
		height: 60px;
		line-height: 60px;
		position: relative;
		text-align: center;
		margin-bottom: 24px;
		margin-right: 24px;
		&.select {
			color: #272e4d;
			border: 1px solid #f32735;
		}
		.icon {
			position: absolute;
			width: 48px;
			height: 48px;
			right: -1px;
			top: -1px;
			overflow: hidden;
			border-radius: 8px;
			font-size: 28px;
			&::before {
				content: '';
				position: absolute;
				right: -24px;
				top: -24px;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background-color: #f32735;
			}
			i {
				position: absolute;
				width: 23px;
				height: 23px;
				right: 0px;
				top: 0px;
				text-align: center;
				line-height: 24px;
			}
		}
		&:hover {
			box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
		}
	}
	.item_purpose {
		width: 316px;
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	.item_exam {
		width: 146px;
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
	.item_subject {
		width: 146px;
		height: 174px;
		padding: 24px;
		overflow: hidden;
		&:nth-child(4n) {
			margin-right: 0;
		}
		&:last-child {
			padding: 24px 0;
		}
		span {
			display: block;
			text-align: center;
			font-size: 16px;
			color: #626573;
			line-height: 22px;
			padding-bottom: 10px;
		}
		svg {
			width: 96px;
			height: 96px;
			vertical-align: -0.15em;
			fill: currentColor;
			overflow: hidden;
		}
	}
	.item_select {
		display: flex;
		line-height: 44px;
		.item_label {
			margin-right: 16px;
			font-size: 16px;
			font-weight: 600;
			color: #272e4d;
		}
		.select_opt {
			font-family: 'Helvetica Neue', 'Source Sans Pro', Arial, Tahoma, Verdana;
			& > div {
				padding: 0;
				border: none;
				height: 44px;
				border-radius: 27px;
				input {
					font-weight: 500;
					color: #272e4d;
					width: 297px;
					border-radius: 27px;
					padding: 8px 27px;
					font-size: 16px;
					border: 1px solid rgba(199, 201, 211, 0.4);
				}
			}
		}
	}

	.btn_box {
		text-align: right;
		padding-top: 24px;
		.btn {
			cursor: pointer;
			display: inline-block;
			text-align: center;
			width: 240px;
			height: 44px;
			line-height: 44px;
			font-size: 16px;
			font-weight: 600;
			color: #fffffe;
			background: linear-gradient(316deg, #ff6536 0%, #f32735 100%);
			border-radius: 22px;
			&.disabled {
				opacity: 0.5;
				cursor: no-drop;
			}
		}
	}
	.textarea {
		width: 100%;
		height: 144px;
		background: #ffffff;
		border-radius: 8px;
		border: 1px solid rgba(199, 201, 211, 0.4);
		padding: 12px 24px 30px;
		position: relative;
		textarea {
			width: 100%;
			height: 100%;
			overflow: auto;
			opacity: 1;
			font-size: 16px;
			font-family: OpenSansRoman-SemiBold, OpenSansRoman;
			font-weight: 600;
			color: #272e4d;
			line-height: 20px;
			&::-webkit-scrollbar {
				width: 0 !important;
			}
			&::placeholder {
				font-family: OpenSansRoman-SemiBold, OpenSansRoman;
				font-weight: 600;
				color: #cecfd5;
				line-height: 20px;
			}
		}
		.limit {
			font-size: 14px;
			color: #999ba4;
			line-height: 19px;
			position: absolute;
			right: 24px;
			bottom: 12px;
		}
		.limit_sign {
			color: #f32735;
		}
	}

	/*手机端*/
	@media screen and (max-width: ( $screenPad - 1px)) {
		.form_wrap {
			height: calc(100% - 56px);
			min-width: 100%;
		}
		.option_warp {
			height: 316px;
			overflow: hidden;
		}
		.question_title {
			font-size: 20px;
			line-height: 27px;
			padding: 19px 0 16px;
		}
		.item_purpose {
			display: block;
			width: 100%;
			margin: 0 0 16px 0;
		}

		.item_exam {
			width: 132px;
			margin-right: 0;
			&:nth-child(odd) {
				margin-right: 16px;
			}
		}
		.item_com {
			height: 48px;
			line-height: 48px;
			&:hover {
				box-shadow: 0 0 0 0px transparent;
			}
		}
		.item_subject {
			width: 132px;
			height: 156px;
			padding: 23px 20px;
			margin-right: 0;
			&:nth-child(odd) {
				margin-right: 16px;
			}
			span {
				padding-bottom: 16px;
			}
			svg {
				width: 86px;
				height: 86px;
			}
		}
		.item_select {
			display: block;
			line-height: 18px;
			.item_label {
				display: inline-block;
				font-size: 14px;
				font-weight: 400;
				color: #272e4d;
				line-height: 18px;
				padding-bottom: 8px;
			}
		}
		.btn_box {
			text-align: center;
			padding-top: 22px;
			.btn {
				width: 180px;
				height: 32px;
				line-height: 32px;
			}
		}
	}
}
/*手机端*/
@media screen and (max-width: ( $screenPad - 1px)) {
	.ac_en_test_modal {
		width: 320px !important;
		min-width: 320px !important;
		height: 524px;
		padding: 20px 19px;
	}
}

:global {
	.opt_select {
		width: 316px;
	}
}
:global {
	.test-form-modal {
		div[class*='select-option'] {
			display: flex;
			align-items: center;
			color: $colorTxt;
			line-height: 18px;
			padding: 9px 24px;
			min-height: 44px;
			font-family: 'Helvetica Neue', 'Source Sans Pro', Arial, Tahoma, Verdana;
			&:hover {
				background-color: $colorBgHover;
				font-weight: bold;
				color: $colorTxtTitle;
			}
			&[class*='selected'] {
				background-color: $colorTxt;
				font-weight: bold;
				color: #fff;
				span {
					display: none;
				}
			}
		}
		span[class*='suffixer'] {
			cursor: pointer;
			span[class*='input-arrow-icon'] {
				font-family: 'iconfont' !important;
				transition: all 0.3s linear;
				&[class*='droped'] {
					transform: rotate(180deg);
				}
				&::before {
					content: '\e637';
					border-top-color: #73747d;
					font-size: 16px;
					border: none;
					position: static;
				}
			}
		}
	}
}
