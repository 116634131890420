:global {
	.zmsp-popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		justify-content: center;
		align-items: center;
		display: flex;
		overflow: auto;
		&.point {
			pointer-events: none;
		}
	}

	.zmsp-popup-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		animation-duration: 0.3s;
		animation-name: antFadeIn;
	}

	.zmsp-popup-inner {
		box-sizing: border-box;
		color: #333;
		font-size: 18px;
		z-index: 1;
	}
}
@keyframes antFadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
