@import 'src/styles/theme.scss';
.logo_container {
	display: flex;
	align-items: center;
	color: $colorTxtTitle;
	.logo_icon {
		width: 180px;
	}
	.split {
		width: 0;
		height: 26px;
		margin: 0 24px;
		border-right: 2px solid $colorBorderLayout;
	}
	.title {
		font-size: 22px;
		font-family: $OpenSans;
		color: #999ba4;
	}

	@media (max-width: 768px) {
		.logo_icon {
			width: 144px;
		}
		.split {
			height: 22px;
			margin: 0 16px;
		}
		.title {
			font-size: 18px;
		}
	}
	@media (max-width: 460px) {
		.logo_icon {
			width: 104px;
		}
		.split {
			height: 16px;
			margin: 0 8px;
		}
		.title {
			font-size: 14px;
		}
	}
}
