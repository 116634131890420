
.course_remind {
	position: absolute;
	bottom: -17px;
	transform: translateY(100%);
	right: 12px;
	display: flex;
	align-items: center;
	width: 280px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	padding: 8px 32px 8px 16px;

	&::before {
		content: "";
		position: absolute;
		top: -9px;
		right: 24px;
		width: 0;
		height: 0;
		border-width: 0 8px 9px 8px;
		border-style: solid;
		border-color: transparent;
		border-bottom-color: rgba(0, 0, 0, 0.5);
	}

	.course_remind_close {
		position: absolute;
		right: 8px;
		top: 8px;
		color: #ffffff;
		cursor: pointer;
	}

	.course_remind_content {
		padding: 2px 0;
		flex: 1;
		min-width: 0;
		line-height: 20px;
    margin-right: 16px;
		color: #fff;
		font-size: 14px;
	}

	.course_remind_btn {
		display: flex;
		align-items: center;
		height: 24px;
		border-radius: 4px;
		padding: 6px;
		border: 1px solid #FFC500;
		font-size: 14px;
		font-family: OpenSansRoman-SemiBold, OpenSansRoman;
		font-weight: 600;
		color: #FFC500;
		cursor: pointer;
    user-select: none;
	}
}

@media (max-width: 460px) {
  .course_remind {
    right: -24px;
    max-width: calc(100vw - 95px);
  }
}