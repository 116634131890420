:global {
	.zmsp-toast {
		box-sizing: border-box;
		padding: 10px 20px;
		max-width: 90%;
		line-height: 24px;
		border: none;
		border-radius: 25px;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.5);
		font-size: 16px;
		text-align: center;
		word-break: break-all;

		& > p {
			margin: 0;
			padding: 0;
		}
	}
}
