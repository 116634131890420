@import 'src/styles/theme.scss';

.ac-footer {
	background-color: #00beca;
	.ac-footer-container {
		position: relative;
		max-width: 1192px;
		margin: 0 auto;
		height: 100%;
	}
	/*手机端*/
	@media screen and (max-width: ( $screenPad - 1px)) {
		& {
			height: auto;
			padding: 0;
			.ac-footer-container {
				width: $bodyPhone;
				.ac-footer-list {
					width: 100%;
					padding-top: 80px;
					padding-left: 60px;

					.ac-footer-item {
						.logo {
							width: 172px;
							height: 21px;
							display: inline-block;
							img {
								width: 100%;
								height: 100%;
							}
						}
						.contact {
							margin-top: 25px;

							.facebook {
								width: 44px;
								height: 44px;
								display: inline-block;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.ins, .WeChat {
								width: 44px;
								height: 44px;
								display: inline-block;
								margin-left: 32px;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.WeChat {
								position: relative;
								cursor: pointer;
								.qrcode {
									width: 184px;
									height: 228px;
									background-color: white;
									border-radius: 12px;
									padding: 10px;
									position: absolute;
									left: 0;
									top: 64px;
									transform: translate(-72px, 0);
									text-align: center;
									display: none;
									> img {
										width: 164px;
										height: 164px;
									}
									> p {
										color: #272E4D;
										font-size: 14px;
										text-align: center;
										font-weight: bold;
										line-height: 18px;
									}
									> span {
										color: #626573;
										font-size: 12px;
									}
								}
								&:hover {
									.qrcode {
										display: block;
									}
								}
							}
							.kidsafeseal {
								width: 125px;
								margin-top: 30px;
								img {
									width: 100%;
								}
							}
						}
						.ac-footer-item-marginTop {
							margin-top: 60px;
						}
						.ac-footer-item-title {
							font-size: 24px;
							font-family: $QuicksandBold, $Quicksand;
							font-weight: bold;
							color: #ffffff;
							line-height: 24px;
							span {
								display: inline-block;
								padding-bottom: 18px;
								border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
							}
						}
						.ac-footer-item-content {
							margin-top: 24px;
							ul > li {
								display: flex;
								align-items: center;
								& > a {
									font-size: 16px;
									color: #ffffff;
									line-height: 24px;
								}
								margin-bottom: 12px;
							}
							.prefix-icon {
								margin-right: 8px;
								width: 10px;
								height: 10px;
								background: #afecf0;
								display: inline-flex;
								border-radius: 50%;
							}
							.prefix-img {
								margin-right: 10px;
								width: 20px;
								height: 20px;
								background: #afecf0;
								display: inline-flex;
								border-radius: 50%;
							}
						}
						&:nth-of-type(1),
						&:nth-of-type(3) {
							text-align: left;
							.ac-footer-item-content {
								display: inline-block;
							}
						}
						&:nth-of-type(2),
						&:nth-of-type(3),
						&:nth-of-type(4) {
							margin-top: 60px;
						}
						&:nth-of-type(3) {
							.ac-footer-item-title {
								span {
									margin-right: 60px;
								}
							}
						}
					}
				}
				.ac-footer-copyright {
					grid-template-columns: 0fr 3fr;
					display: grid;
					width: 100%;
					height: 130px;
					margin-top: 60px;
					border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
					.ac-footer-copyright-content {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						padding: 19px 0 30px;
						& > p {
							font-size: 14px;
							font-family: $OpenSans;
							color: #ffffff;
							line-height: 17px;
							margin-top: 8px;
						}
					}
				}
			}
		}
	}

	/*PAD端*/
	@media screen and (min-width: $screenPad) and (max-width: ( $screenPc - 1px)) {
		& {
			height: 810px;
			padding: 0;
			.ac-footer-container {
				width: $screenPad;
				display: grid;
				grid-template-rows: 1fr 1fr;
				.ac-footer-list {
					width: 100%;
					padding: 80px 80px 0;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-template-rows: repeat(2, 1fr);

					.ac-footer-item {
						.logo {
							width: 172px;
							height: 21px;
							display: inline-block;
							img {
								width: 100%;
								height: 100%;
							}
						}
						.contact {
							margin-top: 45px;

							.facebook {
								width: 44px;
								height: 44px;
								display: inline-block;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.ins, .WeChat {
								width: 44px;
								height: 44px;
								display: inline-block;
								margin-left: 32px;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.WeChat {
								position: relative;
								cursor: pointer;
								.qrcode {
									width: 184px;
									height: 228px;
									background-color: white;
									border-radius: 12px;
									padding: 10px;
									position: absolute;
									left: 0;
									top: 64px;
									transform: translate(-72px, 0);
									text-align: center;
									display: none;
									> img {
										width: 164px;
										height: 164px;
									}
									> p {
										color: #272E4D;
										font-size: 14px;
										text-align: center;
										font-weight: bold;
										line-height: 18px;
									}
									> span {
										color: #626573;
										font-size: 12px;
									}
								}
								&:hover {
									.qrcode {
										display: block;
									}
								}
							}
							.kidsafeseal {
								width: 125px;
								margin-top: 30px;
								img {
									width: 100%;
								}
							}
						}
						.ac-footer-item-marginTop {
							margin-top: 50px;
						}
						.ac-footer-item-title {
							font-size: 24px;
							font-weight: bold;
							color: #ffffff;
							line-height: 24px;
							span {
								display: inline-block;
								padding-bottom: 18px;
								border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
							}
						}
						.ac-footer-item-content {
							margin-top: 32px;
							ul > li {
								display: flex;
								align-items: center;
								& > a {
									font-size: 16px;
									color: #ffffff;
									line-height: 24px;
								}
								margin-bottom: 12px;
							}
							.prefix-icon {
								margin-right: 10px;
								width: 10px;
								height: 10px;
								background: #afecf0;
								display: inline-flex;
								border-radius: 50%;
							}
							.prefix-img {
								margin-right: 10px;
								width: 20px;
								height: 20px;
								background: #afecf0;
								display: inline-flex;
								border-radius: 50%;
							}
						}
						&:nth-of-type(1),
						&:nth-of-type(3) {
							text-align: left;
							.ac-footer-item-content {
								display: inline-block;
							}
						}
						&:nth-of-type(3),
						&:nth-of-type(4) {
							margin-top: 50px;
						}
						&:nth-of-type(3) {
							.ac-footer-item-title {
								span {
									margin-right: 60px;
								}
							}
						}
					}
				}
				.ac-footer-copyright {
					width: 100%;
					height: 79px;
					border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
					.ac-footer-copyright-content {
						text-align: center;
						& > p {
							display: inline-block;
							font-size: 14px;
							color: #ffffff;
							line-height: 24px;
							margin-top: 27px;
							&:last-child{
								display: block;
								margin-top: 8px;
							}
						}
					}
				}
			}
		}
	}

	/*PC端*/
	@media screen and (min-width: $screenPc) {
		& {
			text-align: center;
			height: 480px;
			padding: 0;
			.ac-footer-container {
				display: grid;
				grid-template-rows: 1fr 1fr;
				.ac-footer-list {
					width: 100%;
					height: 400px;
					padding-top: 80px;
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					.ac-footer-item {
						text-align: left;

						.logo {
							width: 172px;
							height: 21px;
							display: block;
							img {
								width: 100%;
								height: 100%;
							}
						}
						.contact {
							margin-top: 45px;

							.facebook {
								width: 44px;
								height: 44px;
								display: inline-block;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.ins, .WeChat {
								width: 44px;
								height: 44px;
								display: inline-block;
								margin-left: 32px;
								img {
									width: 100%;
									height: 100%;
								}
							}
							.WeChat {
								position: relative;
								cursor: pointer;
								.qrcode {
									width: 184px;
									height: 228px;
									background-color: white;
									border-radius: 12px;
									padding: 10px;
									position: absolute;
									left: 0;
									top: 64px;
									transform: translate(-72px, 0);
									text-align: center;
									display: none;
									> img {
										width: 164px;
										height: 164px;
									}
									> p {
										color: #272E4D;
										font-size: 14px;
										text-align: center;
										font-weight: bold;
										line-height: 18px;
									}
									> span {
										color: #626573;
										font-size: 12px;
									}
								}
								&:hover {
									.qrcode {
										display: block;
									}
								}
							}
							.kidsafeseal {
								margin-top: 30px;
								width: 125px;
								img {
									width: 100%;
								}
							}
						}
						.ac-footer-item-marginTop {
							margin-top: 50px;
						}
						.ac-footer-item-title {
							font-size: 24px;
							font-weight: bold;
							color: #ffffff;
							line-height: 24px;
							span {
								display: inline-block;
								padding-bottom: 18px;
								border-bottom: 1px solid rgba($color: #fff, $alpha: 0.5);
							}
						}
						.ac-footer-item-content {
							margin-top: 32px;
							ul > li {
								display: flex;
								align-items: center;
								& > a {
									font-size: 16px;
									color: #ffffff;
									line-height: 24px;
								}
								margin-bottom: 12px;
							}
							.prefix-icon {
								margin-right: 10px;
								width: 10px;
								height: 10px;
								background: #afecf0;
								display: inline-flex;
								border-radius: 50%;
							}
							.prefix-img {
								margin-right: 10px;
								width: 20px;
								height: 20px;
								background: #afecf0;
								display: inline-flex;
								border-radius: 50%;
							}
						}
					}
				}
				.ac-footer-copyright {
					grid-template-columns: 1fr 3fr;
					display: grid;
					width: 100%;
					height: 79px;
					border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
					.ac-footer-copyright-content {
						display: flex;
						align-items: center;
						& > p {
							font-size: 14px;
							color: #ffffff;
							line-height: 24px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}
.link{
	color: #fff;
}
