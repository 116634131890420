/*-----------------------------Quicksand font-----------------------------*/
@font-face {
	font-family: 'Quicksand-Bold';
	src: url('https://web-data.zmlearn.com/doc/fwm8497vNZJeRJapNqErvm/Quicksand-Bold.ttf');
}

@font-face {
	font-family: 'Quicksand';
	src: url('https://web-data.zmlearn.com/doc/fwm8497vNZJeRJapNqErvm/Quicksand-Bold.ttf');
}

/*-----------------------------OpenSans font-----------------------------*/
@font-face {
	font-family: 'OpenSans-Bold';
	src: url('https://web-data.zmlearn.com/doc/2eKEXXWQPYw6R3jyVLR4rC/OpenSans-Bold.ttf');
}

@font-face {
	font-family: 'OpenSans';
	src: url('https://web-data.zmlearn.com/doc/uzPVhVBTJJcW9thU1VHuLy/OpenSans-Regular.ttf');
}

@font-face {
	font-family: 'OpenSans-SemiBold';
	src: url('https://web-data.zmlearn.com/doc/aWWga9kNtrLoha1Qj5oTgQ/OpenSans-SemiBold.ttf');
}
