.select-menu {
	width: 270px;
	padding: 8px;
	background: #ffffff;
	box-shadow: 0px 7px 34px 0px rgba(31, 60, 103, 0.16);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	margin-top: 7px;
	&:not(.IsMobile) {
		max-height: 200px;
	}
	.header {
		flex: none;
		margin-bottom: 10px;
		height: 34px;
		position: relative;
		.select-input {
			width: 100%;
		}
		.raw-input {
			border: none;
			background: #f4f9fe;
		}
	}
	.bar {
		display: none;
	}
	.list {
		flex: auto;
		min-height: 0;
		font-size: 14px;
		font-family: SanFranciscoDisplay-Regular, SanFranciscoDisplay;
		font-weight: 400;
		color: #272e4d;
		line-height: 16px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			width: 4px;
			min-height: 40px;
			border: 2px solid transparent !important;
			background: rgba(0, 0, 0, 0.1);
			background-clip: content-box !important;
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
			border: none;
			box-shadow: none;
		}
		.scroller {
			position: relative;
		}
	}
}
.select-option {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 5px 0;
	min-height: 26px;
	&:hover {
		background-color: #c7cbd3;
	}
	&.selected {
		.selected-icon {
			display: block;
		}
	}
	.selected-icon {
		display: none;
		margin: 0 8px 3px auto;
		&::after {
			content: '';
			display: block;
			margin-left: 8px;
			width: 12px;
			height: 7px;
			border: 2px solid #f32735;
			border-width: 0 0 2px 2px;
			transform: rotate(-48deg);
		}
	}
}

.raw-input {
	box-sizing: border-box;
	height: 34px;
	padding: 8px;
	border-radius: 6px;
	border: 1px solid rgba(199, 201, 211, 0.4);
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
	outline-style: none;
}
.select-input {
	cursor: pointer;
	.raw-input {
		width: 100%;
	}
	&.has-prefix {
		position: relative;
		.prefixer {
			position: absolute;
			left: 0;
			top: 0;
			width: 32px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		}
		.raw-input {
			padding-left: 32px;
		}
	}
	&.has-suffix,
	&.allow-clear {
		position: relative;
		.clear,
		.suffixer {
			position: absolute;
			right: 0;
			top: 0;
			width: 32px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		}
		.raw-input {
			padding-right: 32px;
		}
	}
	&.allow-clear.has-suffix {
		.clear {
			display: none;
		}
		&:hover {
			.clear {
				display: block;
			}
			.suffixer {
				display: none;
			}
		}
	}
}
.select-input-search-icon {
	width: 16px;
	height: 16px;
	img {
		width: 100%;
	}
}
.select-input-clear-icon {
	width: 12px;
	height: 12px;
	img {
		width: 100%;
	}
}
.select-input-arrow-icon {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		left: -6px;
		top: -2px;
		width: 0;
		height: 0;
		border-top: solid 8px #c7c9d3;
		border-left: solid 6px transparent;
		border-right: solid 6px transparent;
	}
	&.droped::before {
		transform: rotate(180deg);
	}
}
.IsMobile {
	position: fixed !important;
	left: 0 !important;
	top: unset !important;
	bottom: 0;
	width: 100%;
	height: 434px;
	padding: 16px 16px 0;
	border-radius: 20px 20px 1px 1px;
	z-index: 1000;
	&::before {
		content: '';
		position: absolute;
		top: 262px;
		bottom: 0;
		left: 0;
		height: 44px;
		width: 100%;
		background: #f7f8f9;
		border-radius: 22px;
		pointer-events: none;
	}
	.header {
		margin-bottom: 14px;
		height: 44px;
		.select-input {
			&.has-prefix {
				.raw-input {
					padding-left: 52px;
				}
				.prefixer {
					width: 52px;
					img {
						width: 18px;
					}
				}
			}
		}
		.raw-input {
			height: 44px;
			border-radius: 24px;
			border: 1px solid #c7c9d3;
			font-size: 18px;
			background-color: #fff;
		}
	}
	.bar {
		flex: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 42px;
		margin-bottom: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		line-height: 22px;
		button {
			padding: 5px 0;
			width: 80px;
			text-align: center;
			color: #272e4d;
			font-size: 16px;
			border-radius: 20px;
			background-color: rgba(247, 248, 249, 0.5);
			&:last-child {
				color: #1f4d97;
			}
		}
	}
	.list {
		z-index: 1;
		.scroller {
			padding: 132px 0 128px;
		}
	}
	.select-option {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 44px;
		padding: 4px 0;
		font-size: 16px;
		color: #9a9da9;
		line-height: 18px;
		text-align: center;
		overflow: hidden;
		&:hover {
			background-color: unset;
		}
		&.hovered,
		&.selected {
			color: #272e4d;
			font-weight: 600;
		}
		&.selected {
			background-color: unset;
		}
	}
	&:not(.allow-search) {
		&::before {
			top: 204px;
		}
		.scroller {
			padding: 132px 0 186px;
		}
	}
}
