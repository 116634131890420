:global {
	.zmsp-modal {
		width: 600px;
		height: 600px;
		background: #fff;
		border-radius: 80px;

		.close-btn {
			position: absolute;
			top: -25px;
			right: -25px;
			display: block;
			width: 50px;
			height: 50px;
			background-image: url('https://web-data.zmlearn.com/image/4gEpdbkuXdNTqcaRj4KXgi/icon_close%402x.png');
			background-repeat: no-repeat;
			background-size: contain;
			cursor: var(--global-cursor-pointer);
		}
	}

	.zmsp-new-modal {
		width: 640px;
		height: 640px;
		background: #fff;
		border-radius: 94px;
		.close-btn {
			height: 50px;
			width: 50px;
			position: absolute;
			top: -22px;
			right: -22px;
			background: url(https://web-data.zmlearn.com/image/4gEpdbkuXdNTqcaRj4KXgi/icon_close%402x.png) no-repeat;
			background-size: contain;
			background-repeat: no-repeat;
			background-size: contain;
			cursor: var(--global-cursor-pointer);
		}
	}
}
