:root {
	--prefix: 'ac';
}

$prefix: 'ac';

$colorPrimary: #f32735;
// icon 颜色
$colorIcon: #999ba4;
$colorIconHover: #73747d;
$colorIconMenuHover: #272e4d;
// 文字颜色
$colorTxt: #626573; // 主色
$colorTxtTitle: #272e4d;
$colorTxtMinor: #60636f; // 次色
$colorTxtSlim: #999ba4;
$colorTxtLink: #0379ff;
$colorTxtSign: #5ea9ff;
$colorPlaceholder: #cecfd5;
// 背景色
$colorBg: #fff;
$colorBgHover: #f4f5f6;
// border颜色
$colorBorderLayout: rgba(199, 201, 211, 0.5);
$colorBorderCard: #efeff1;
// 蒙层颜色
$colorMask: rgba(0, 0, 0, 0.6);

$colorAlertSuccess: rgba(82, 196, 26, 0.5);
$colorAlertWarn: rgba(255, 168, 0, 0.5);
$colorAlertError: rgba(255, 42, 36, 0.5);
$colorAlertSuccessBg: #f4fbf1;
$colorAlertWarnBg: #fffaef;
$colorAlertErrorBg: #ffeeee;
$colorAlertSuccessIcon: #52c41a;
$colorAlertWarnIcon: #ffa800;
$colorAlertErrorIcon: #ff2a24;

//字体 family
$OpenSansMedium: OpenSans-SemiBold;
$OpenSans: OpenSans;
$OpenSansBold: OpenSans-Bold;
$Quicksand: Quicksand;
$QuicksandBold: Quicksand-Bold;

//屏幕尺寸
$screenPhone: 375px;
$screenPad: 768px;
$screenPc: 1192px;

//版心
$bodyPhone: 343px;
$bodyPad: 720px;
$bodyPc: 1192px;

/*手势动画*/
@keyframes scaleDraw {
	0% {
		transform: scale(0.8);
	}
	25% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.8);
	}
	75% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
	}
}

/*select伸缩动画*/

@mixin stretch($name, $height) {
	@keyframes #{$name} {
		from {
			// transform: translateY(100%);
			height: 0%;
			// opacity: 0;
		}
		to {
			// transform: translateY(0%);

			height: $height;
			// opacity: 1;
		}
	}
}

@keyframes PhoneStretch {
	from {
		transform: translateY(100%);
		// height: 0%;
		// opacity: 0;
	}
	to {
		transform: translateY(0%);

		// height: $height;
		// opacity: 1;
	}
}

@include stretch(Stretch, 100%);
// @include stretch(PhoneStretch, 434px);
@include stretch(SelectStretch, 388px);

/* 抽屉动画 */
@keyframes translateX {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
}

/* 抽屉动画-从上到下 */
@keyframes translateY {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0%);
	}
}

/*弹出框动画*/
@keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}
