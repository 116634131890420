// 变量
html {
	--home-active-btn-z: 5;
	--home-content-z: 6;
	--home-tree-z: 7;
	--home-tree-door-z: 8;
	--home-partner-z: 9;
	--home-scroller-arrow-z: 10;

	// 安全中心 蓝光
	--safe-eyes-blue: 1000;
	// 眼保健操
	--safe-eyes-eyes: 1001;

	// 意见反馈漂浮窗
	--feedback-img: 90;
}

// 鼠标pointer图片
html {
	--global-cursor-pointer: -webkit-image-set(
		url(https://web-data.zmlearn.com/image/cGAMiANo6BksfERvmXc7Q9/mousehand_40.png) 1x,
		url(https://web-data.zmlearn.com/image/aXrktpsjMVPNpqN3vCdkhq/mousehand_80.png) 2x),
		default;
}
