$dark-color: #fff;
$pd: 8px;
.zmkpc-popover {
	position: absolute;
	display: block;
	z-index: 101;
	animation-name: antSlideDownIn;
	animation-duration: 0.3s;

	// animation-timing-function: ease-out;
	&.zmkpc-popover-enter {
		.zmkpc-popover-content {
			opacity: 1;
		}
	}

	&.ghost {
		pointer-events: none;
	}

	&.zmkpc-popover-bottom {
		padding-top: $pd;

		.zmkpc-popover-arrow {
			z-index: 2;
			top: -4px;
			left: 10px;
			transform: translateX(-50%) rotate(45deg);
			border-color: $dark-color transparent transparent $dark-color;

			&.right {
				right: 0;
				left: inherit;
			}
		}
	}

	&.zmkpc-popover-top {
		padding-bottom: $pd;

		.zmkpc-popover-arrow {
			bottom: -4px;
			left: 10px;
			transform: translateX(-50%) rotate(45deg);
			border-color: transparent $dark-color $dark-color transparent;

			&.right {
				right: 0;
				left: inherit;
			}
		}
	}

	&.zmkpc-popover-left {
		padding-right: $pd;

		.zmkpc-popover-arrow {
			right: -4px;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
		}
	}

	&.zmkpc-popover-right {
		padding-left: $pd;
		transform: translateY(-50%);

		.zmkpc-popover-arrow {
			left: -4px;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
		}
	}

	.zmkpc-popover-content {
		position: relative;
		opacity: 0;
		transition: opacity 0.2s ease;
	}

	.zmkpc-popover-content-inner {
		position: relative;
		border-radius: 4px;
	}

	.zmkpc-popover-arrow {
		box-sizing: border-box;
		position: absolute;
		display: block;
		width: 12px;
		height: 12px;
		background: transparent;
		border-style: solid;
		border-width: 6px;
		transform: rotate(45deg);
		box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.07);
	}
}

@keyframes antSlideDownIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}

	to {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
}
