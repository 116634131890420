@import 'src/styles/theme.scss';
.header {
	flex: none;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	background-color: #fff;
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.09);
	z-index: 10;
	.inner {
		flex: auto;
		width: 100%;
		height: 90px;
		display: flex;
		align-items: center;
		max-width: 1180px;
	}
	@media (max-width: 1152px) {
		.inner {
			padding: 0 24px;
		}
	}
	@media (max-width: 767px) {
		.inner {
			padding: 0 16px;
		}
	}
	@media (max-width: 460px) {
		.inner {
			height: 52px;
		}
	}
}
