@import 'src/styles/theme.scss';

:global(.nationalflag) {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}

.area_select {
	width: 142px;
	height: 54px;
	display: flex;
	align-items: center;
	cursor: pointer;
	.icon {
		font-size: 24px;
		width: 24px;
		margin-left: 24px;
	}
	.code {
		width: 46px;
		margin: 0 8px;
		font-size: 16px;
	}
}

:global(.select_menu) {
	.area_item {
		display: flex;
		align-items: center;
		color: $colorTxt;
		line-height: 18px;
		padding: 9px 24px;
		min-height: 44px;
		&:hover {
			background-color: $colorBgHover;
			font-weight: bold;
			color: $colorTxtTitle;
		}
		&.selected {
			background-color: #626573;
			font-weight: bold;
			color: #fff;
		}
		.icon {
			flex: none;
			width: 24px;
			font-size: 24px;
			margin-right: 16px;
			font-weight: normal;
		}
		.name {
			flex: auto;
		}
		.code {
			flex: none;
			width: 46px;
			margin-left: 16px;
			text-align: right;
		}
	}
}
:global(.select_menu.m) {
	.area_item.selected {
		background-color: $colorBgHover;
		font-weight: bold;
		color: $colorTxtTitle;
	}
}
