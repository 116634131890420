@import 'src/styles/theme.scss';
.ac-nav-menu-droped-body {
	overflow: hidden;
}
.nav_menu {
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	a,
	.more,
	.lang {
		padding: 8px 16px;
		flex-shrink: 0;
	}
	.invite_nav {
		margin: 0 24px;
		img {
			width: 240px;
			position: relative;
			bottom: 4px;
		}
	}
	.nav_item {
		// margin-right: 32px;
		font-size: 16px;
		// height: 34px;
		color: $colorTxt;
		line-height: 34px;
		font-family: $OpenSans;
		&:hover {
			color: $colorIconMenuHover;
		}
	}
	.nav_active {
		display: flex;
		align-items: center;
		justify-content: center;
		.nav_item {
			font-family: $OpenSansMedium;
			color: $colorTxtTitle;
			font-weight: bold;
			background-image: url(https://web-data.zmlearn.com/image/9EsqQa8iRDEDXqomNihMpZ/%E6%98%9F%E5%BD%A2%E5%A4%87%E4%BB%BD%203%402x.png);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			height: 34px;
		}
	}
	.menu_bar {
		cursor: pointer;
		display: flex;
		// .login + i {
		// 	display: none;
		// }
		// .unlogin {
		// 	display: none;
		// }
	}
	.menu_bar > i {
		width: 30px;
		font-size: 30px;
		color: $colorIcon;
		cursor: pointer;
		&:hover {
			color: $colorIconMenuHover;
		}
	}

	.user_info {
		display: flex;
		align-items: center;
		.avatar {
			width: 44px;
			height: 44px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: center;
			}
		}
	}
	.more {
		display: flex;
		align-items: center;
		border-radius: 4px;

		&:hover {
			background: #f4f5f6;
			.title {
				color: $colorTxtTitle;
			}
		}
		.title {
			font-size: 16px;
			color: $colorTxt;
		}
	}
	.icon {
		margin-left: 8px;
		width: 16px;
		transition: all 0.3s linear;
		transform: rotate(0deg);
		&.droped {
			transform: rotate(180deg);
			i {
				color: $colorIconMenuHover;
			}
		}
		i {
			font-size: 16px;
			color: $colorIcon;
		}
	}
	.lang {
		display: flex;
		align-items: center;
		border-radius: 4px;
		&:hover {
			background: #f4f5f6;
			.title {
				color: $colorTxtTitle;
			}
			.icon i {
				color: $colorTxtTitle;
			}
		}
		.icon {
			width: 20px;
			margin-left: 0px;
			margin-right: 6px;

			i {
				font-size: 20px;
				color: $colorIcon;
			}
		}
		.title {
			font-size: 16px;
			font-family: $OpenSans;
			font-weight: 400;
			color: $colorTxt;
		}
	}
}

.nav_menu_popup.drop {
	position: absolute;
	right: -40px;
	display: flex;
	flex-direction: column;
	.menu {
		box-shadow: 0px 8px 32px 0px rgba(31, 60, 103, 0.1);
		border: 1px solid rgba(199, 201, 211, 0.4);
		border-radius: 8px;
	}
}
.nav_menu_popup.aside {
	position: fixed;
	width: 100%;
	// height: 85%;
	// bottom: 15%;
  top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	background-color: $colorBg;
	border-radius: 0 0 20px 20px;
	overflow: hidden;
	animation-name: translateY;
	animation-duration: 0.5s;
	.aside_title {
		height: 91px;
		display: flex;
		align-items: center;
		i {
			font-size: 30px;
			color: $colorIcon;
			margin-left: auto;
			margin-right: 24px;
			cursor: pointer;
			&:hover {
				color: $colorIconHover;
			}
		}
	}
	.menu {
		width: 100%;
		flex: 1;
		min-height: 0;
		overflow-y: auto;
		padding: 0 0 20px;
    max-height: 80vh;

		.menu_item {
			padding: 6px 24px;
			margin: 8px 0;
			height: 36px;
		}
	}
}
.menu {
	width: 220px;
	padding: 8px 0;
	background-color: $colorBg;
	.menu_item {
		padding: 10px 11px 10px 23px;
		height: 44px;
		display: flex;
		align-items: center;
		color: $colorTxt;
		font-size: 16px;
		font-family: $OpenSansMedium;
		&.menu_item_active {
			background-color: $colorTxt;
			span {
				font-size: 16px;
				font-family: $OpenSans;
				font-weight: 400;
				color: #fff;
			}
			i {
				color: #fff;
			}
			&:hover {
				background-color: $colorTxt;
				i {
					color: #fff;
				}
			}
		}
		cursor: pointer;
		@media (min-width: 768px) {
			&:hover {
				color: $colorTxtTitle;
				background-color: $colorBgHover;
				i {
					color: $colorIconMenuHover;
				}
			}
		}
		i {
			margin-right: 12px;
			font-size: 24px;
			color: $colorIcon;
		}
	}
	.svg_img{
		width: 24px;
		height: 24px;
		margin-right:12px;
		color: $colorIcon;
	}
}

.login_btn {
	cursor: pointer;
	display: inline-block;
	padding: 8px 16px;
	font-size: 16px;
	color: #626573;
	line-height: 34px;
	font-family: OpenSans;
}

.relative_wrap {
	position: relative;
}

@media screen and (min-width: 768px) {
	.lang_popup {
		.menu_item {
			padding: 12px 23px;
			i {
				display: none;
			}
			&.menu_item_active {
				background-color: $colorTxt;
				span {
					font-size: 16px;
					font-family: $OpenSans;
					font-weight: 400;
					color: #fff;
				}
			}
		}
	}
}

@media (max-width: 680px) {
	.nav_menu .invite_nav {
		display: none;
	}
}
@media (max-width: 460px) {
	.nav_menu {
		.menu_bar .user_info {
			display: none;
			& + i {
				display: block;
				width: 24px;
				font-size: 24px;
			}
		}

		.more {
			padding-right: 0;
		}
	}
	.nav_menu_popup.aside {
		width: 100%;
		.aside_title {
			height: 52px;
			i {
				font-size: 24px;
				margin-right: 16px;
			}
		}
	}
	div[class*='zmkpc-popover-arrow'] {
		border-color: rgba(0, 0, 0, 0.8) transparent transparent rgba(0, 0, 0, 0.8) !important;
	}
	.lang_popup {
		.menu {
			background-color: rgba(0, 0, 0, 0.8);
			width: 140px;
			.menu_item {
				padding: 8px 16px;
				color: #ffffff;
				font-size: 14px;
				font-family: $OpenSans;
				font-weight: 400;
				justify-content: space-between;
				flex-direction: row-reverse;
				i {
					margin: 0;
					opacity: 0;
					margin-left: 20px;
				}
				&.menu_item_active {
					i {
						color: #fff;
						opacity: 1;
					}
				}
			}
		}
	}
}
.menu_item{
	.svg{
		display: inline-block;
		width: 24px;
		height: 24px;
		margin-right: 12px;
	}
}

