.main-container {
	&.show-header {
		min-height: calc(100vh - 480px);
		padding-top: 90px;
	}
	&.not-show-header {
		height: 100vh;
	}
	@media (max-width: 1199px) {
		&.show-header {
			padding-top: 90px;
		}
	}
	@media (max-width: 767px) {
		&.show-header {
			// min-height: calc(100vh - 52px);
			min-height: unset;
			padding-top: 90px;
		}
		&.not-show-header {
			// min-height: calc(100vh - 52px);
			min-height: unset;
		}
	}
	@media (max-width: 460px) {
		&.show-header {
			// min-height: calc(100vh - 52px);
			min-height: unset;
			padding-top: 52px;
		}
		&.not-show-header {
			// min-height: calc(100vh - 52px);
			min-height: unset;
		}
	}
}
